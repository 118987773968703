body {
  margin-top: -20%;
  background: rgba(244, 244, 244, 0.8);
  animation: bgg 16087ms ease-in alternate infinite;
  width: intrinsic; /* Safari/WebKit uses a non-standard name */
  width: -moz-max-content; /* Firefox/Gecko */
  width: -webkit-max-content; /* Chrome */
}
@keyframes bgg {
  0% {
    background: rgba(244, 244, 244, 0.8);
  }
  50% {
    background: rgba(244, 244, 244, 0.8);
  }
  100% {
    background: rgba(244, 244, 244, 0.8);
  }
}
.loader {
  z-index: 99999;
  position: absolute;
  width: 100%;
  height: 100%;
}

.loader .circle {
  position: absolute;
  left: 40%;
  top: 50%;
  width: 20%;
  height: 10%;
  opacity: 0;
  transform: rotate(225deg);
  animation-iteration-count: infinite;
  animation-name: orbit;
  animation-duration: 5.5s;
}
.loader .circle:after {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 3px;
  background: #1b73e8;
  box-shadow: 0 0 9px #1b73e8;
}
.loader .circle:nth-child(2) {
  animation-delay: 240ms;
}
.loader .circle:nth-child(3) {
  animation-delay: 480ms;
}
.loader .circle:nth-child(4) {
  animation-delay: 720ms;
}
.loader .circle:nth-child(5) {
  animation-delay: 960ms;
}
.loader .bg {
  height: 100%;
  background-color: rgba(0, 153, 255, 0.69);
  animation: bgg 16087ms ease-in alternate infinite;
}
@keyframes orbit {
  0% {
    transform: rotate(225deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  7% {
    transform: rotate(345deg);
    animation-timing-function: linear;
  }
  30% {
    transform: rotate(455deg);
    animation-timing-function: ease-in-out;
  }
  39% {
    transform: rotate(690deg);
    animation-timing-function: linear;
  }
  70% {
    transform: rotate(815deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(945deg);
    animation-timing-function: ease-out;
  }
  76% {
    transform: rotate(945deg);
    opacity: 0;
  }
  100% {
    transform: rotate(945deg);
    opacity: 0;
  }
}
