.containerProductCard {
  width: 22.7%;
  height: 566px;
  margin: 27px 0px 17px 1.5%;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.titleContainer {
  width: 93.55%;
  height: 14%;
  margin: 0 3.23% 0 3.23%;
  border-bottom: 1px solid #e6e6e6;
}

.titleText {
  padding: 34px 0px 34px 0px;
  font-family: Avenir;
  font-size: 25px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
}
@media screen and (max-width: 1120px) {
  .titleText {
    font-size: 150%;
  }
}

.containerImageCard {
  margin: 0 18px;
  background: #f8f8f8;
  border-radius: 10px;
}
.referenceTitle {
  width: 90%;
  height: 60px;
  margin: 14px 10px 5px 10px;
  padding-top: 15px;
  font-family: Avenir;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
}

@media screen and (max-width: 1120px) {
  .referenceTitle {
    font-size: 110%;
  }
}

.containerImage {
  display: flex;
  justify-content: space-around;
  height: 210px;
}

.leftBottom {
  background-color: #ffffff;
  border: none;
  text-align: center;
  text-decoration: none;
  margin: 180px 0px 30px 0%;
  width: 24px;
  height: 24.1px;
  align-self: center;
  flex-grow: 0;
  object-fit: contain;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}
.leftBottom:hover {
  cursor: pointer;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.25);
}
.leftBottomImage {
  margin: -100% 0px 0 -125%;
}
.leftBottomDisabled {
  background-color: #ffffff;
  border: none;
  text-align: center;
  text-decoration: none;
  margin: 180px 0px 30px 0%;
  width: 24px;
  height: 24.1px;
  align-self: center;
  flex-grow: 0;
  object-fit: contain;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.leftBottomImageDisabled {
  transform: rotate(180deg) scale(0.8);
  margin: -35% 0px 0 -70%;
}
.image {
  width: 72.6%;
  height: 100%;
}
.rightBottom {
  margin: 180px 0 30px 0;
  background-color: #ffffff;
  border: none;
  text-align: center;
  text-decoration: none;
  width: 24px;
  height: 24.1px;
  align-self: center;
  flex-grow: 0;
  object-fit: contain;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}
.rightBottom:hover {
  cursor: pointer;
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.25);
}

.rightBottomImage {
  margin: -100% 0px 0 -125%;
}

.rightBottomDisabled {
  margin: 180px 0 30px 0;
  border: none;
  text-align: center;
  text-decoration: none;
  width: 24.1px;
  height: 24.1px;
  align-self: center;
  flex-grow: 0;
  object-fit: contain;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
}

.rightBottomImageDisabled {
  transform: scale(0.8);
  margin: -35% 0px 0 -70%;
}

.label {
  width: 93.55%;
  height: 35px;
  margin: 3% 3.23% 0 3.23%;
  border-bottom: 1px solid #e6e6e6;
  border-top: 1px solid #e6e6e6;
}

.containerMyPrice {
  display: flex;
}

.containerPriceTopTurnover {
  margin: 0px 10px 0 0px;
  width: 100%;
}

.topTurnover {
  width: 48%;
  height: 39px;
  margin: 10px 0px 7px 0px;
}

.supText {
  border-top: 1px solid #e6e6e6;
  color: #363636;
  flex-grow: 0;
  padding-top: 19.5px;
  margin: 45.5px 26px 2px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
}

@media screen and (max-width: 1260px) {
  .supText {
    font-size: 110%;
  }
}

@media screen and (max-width: 1140px) {
  .supText {
    font-size: 90%;
  }
}

.inputTopTurnoverPrice {
  width: 84.67%;
  height: 70px;
  flex-grow: 0;
  margin: 2px 20px 0;
  font-family: Avenir;
  font-size: 70px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
}

@media screen and (max-width: 1120px) {
  .inputTopTurnoverPrice {
    font-size: 350%;
  }
}

.description {
  width: 92%;
  height: 35px;
  flex-grow: 0;
  margin: 45px 4% 38px 4%;
  font-family: Avenir;
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
}
@media screen and (max-width: 1120px) {
  .description {
    font-size: 90%;
  }
}

.goForm {
  cursor: pointer;
  width: 88%;
  height: 41px;
  flex-grow: 0;
  margin: 0px 6% 0 6%;
  padding: 2% 2% 2% 2%;
  border-radius: 7.2px;
  background-color: #1b73e8;
  flex-grow: 0;
  font-family: Avenir;
  font-size: 96%;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

@media screen and (max-width: 1120px) {
  .goForm {
    font-size: 85%;
  }
}
