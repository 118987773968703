.container {
  width: 100%;
  height: 8.1%;
  position: relative;
  z-index: 9;
  padding: 0px 5px;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  justify-content: space-evenly;
}

.logoHeader {
  position: relative;
  margin-left: 1%;
  margin-top: 6px;
  width: 80px;
  height: 35px;
}

.containerLogos {
  display: flex;
  margin: 2px 10px 8px 40%;
}

.circleBlack {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin: 5px;
}
