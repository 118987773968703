.containerProductCards {
  z-index: 999;
  position: absolute;
  will-change: transform, opacity;
  transform: translate(-50%, 80%);
  padding: 8px;
  width: 73%;
  height: 14%;
  background-color: #ffffff;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  border: 1.26829px solid #1b73e8;
  box-sizing: border-box;
  border-radius: 5px;
}

.containerProductCard {
  z-index: 999;
  transform: scale(0.95);
  background-color: #ffffff;
  display: flex;
}

@media screen and (max-height: 620px) {
  .containerProductCards {
    transform: translate(-50%, 70%);
  }
}

@media screen and (max-height: 520px) {
  .containerProductCards {
    transform: translate(-50%, 60%);
  }
}

.containerImageCard {
  width: 25%;
  height: 84%;
}

.image {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.containerInfo {
  width: 75%;
  margin-left: 10px;
}
.referenceTitle {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #363636;
}

@media screen and (max-width: 340px) {
  .referenceTitle {
    font-size: 12px;
  }
}

.supText {
  font-family: Avenir;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  color: #363636;
}

@media screen and (max-width: 340px) {
  .supText {
    font-size: 9px;
  }
}

.inputTopTurnoverPrice {
  font-family: Avenir;
  font-size: 19px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.9;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

@media screen and (max-width: 340px) {
  .inputTopTurnoverPrice {
    font-size: 18px;
  }
}

.goEcommerce {
  flex-grow: 0;
  font-family: Avenir;
  font-size: 11px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #00d27c;
}

@media screen and (max-width: 340px) {
  .goEcommerce {
    font-size: 10px;
  }
}

.containerProductCardsPOS {
  will-change: transform, opacity;
  transform: translate(20%, 120%);
  width: 73%;
  height: 14%;
  background-color: #f8f8f8;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  border: 1.26829px solid #1b73e8;
  box-sizing: border-box;
  border-radius: 5px;
}

@media screen and (max-height: 620px) {
  .containerProductCardsPOS {
    transform: translate(20%, 160%);
  }
}

@media screen and (max-height: 560px) {
  .containerProductCardsPOS {
    transform: translate(20%, 170%);
  }
}

.containerProductCardPOS {
  z-index: 999;
  background-color: #f8f8f8;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  box-sizing: border-box;
  border-radius: 5px;
}

.textPos {
  padding: 5px 15px;
  font-family: Avenir;
  font-size: 90%;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
}

@media screen and (max-width: 340px) {
  .textPos {
    font-size: 85%;
  }
}
