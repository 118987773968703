.container {
  width: 100%;
  height: 78%;
  background-color: #f8f8f8;
}

.containerTitle {
  width: 85.6%;
  height: 10.2%;
  margin: 18% 7.2% 0% 7.2%;
  font-family: Avenir;
  font-size: 190%;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: normal;
  flex-grow: 0;
  text-align: center;
  color: #363636;
}

@media screen and (max-width: 388px) {
  .containerTitle {
    font-size: 170%;
  }
}
@media screen and (max-width: 340px) {
  .containerTitle {
    font-size: 150%;
  }
}

.containerSubTitle {
  width: 76.1%;
  height: 6.7%;
  padding-bottom: 12%;
  border-bottom: 2px solid #979797;
  margin: 8% 12% 6% 12%;
  font-family: Avenir;
  font-size: 110%;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
}

@media screen and (max-width: 340px) {
  .containerSubTitle {
    font-size: 90%;
  }
}

.list {
  width: 50.7%;
  height: 6.7%;
  margin: 4.9% 23.6% 42.1% 23.6%;
  border-radius: 3.6px;
  border: solid 0.6px rgba(54, 54, 54, 0.4);
  max-width: 100%;
  box-sizing: border-box;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.03);
  background-image: linear-gradient(to bottom, #ffffff 0%, #f7f7f7 100%);
  background-repeat: no-repeat, repeat;
  background-position: right 0.65em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  padding: 0 5.1% 0 1.7%;
  background-color: #ffffff;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -o-appearance: none !important;
  appearance: none !important;
  color: #979797;
  text-align: left;
  font-family: Avenir;
  font-size: 90%;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

@media screen and (max-width: 340px) {
  .list {
    font-size: 80%;
  }
}

.valueDefault {
  width: 50%;
  display: none;
  text-align: left;
  font-family: Avenir;
  font-size: 90%;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #979797;
}

@media screen and (max-width: 340px) {
  .valueDefault {
    font-size: 80%;
  }
}

.list:focus {
  border-color: #aaa !important;
  box-shadow: 0 0 0 3px -moz-mac-focusring !important;
  color: #222;
  outline: none !important;
}

.list option {
  height: 4.7%;
  flex-grow: 0;
  font-family: Avenir;
  font-size: 100%;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #58595b;
}

@media screen and (max-width: 340px) {
  .list option {
    font-size: 90%;
  }
}

.buttonNoChecked {
  width: 58%;
  height: 7%;
  flex-grow: 0;
  margin: 0 21% 0 21%;
  padding: 0 8.4% 0 8.2%;
  border-radius: 5.5px;
  background-color: rgba(0, 0, 0, 0.12);
  font-family: Avenir;
  font-size: 90%;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: rgba(54, 54, 54, 0.38);
}

@media screen and (max-width: 340px) {
  .buttonNoChecked {
    font-size: 80%;
  }
}

.buttonChecked {
  width: 58%;
  height: 7%;
  margin: 0 21% 0 21%;
  padding: 0 8.4% 0 8.2%;
  border-radius: 5.5px;
  background-color: #1b73e8;
  font-family: Avenir;
  font-size: 90%;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 340px) {
  .buttonChecked {
    font-size: 80%;
  }
}
