.container {
  touch-action: pan-x pan-y;
}

.map {
  border-radius: 10px !important;
  width: auto;
}

.popupBubble {
  visibility: visible !important;
  position: absolute;
  transform: translate(25%, -200%);
  padding: 5px;
  font-family: Avenir;
  background-color: #1b73e8;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  z-index: 9;
  flex-grow: 0;
  border-radius: 4.6px;
  border: solid 1px #ffffff;
  background-color: #1b73e8;
}

.popupBubbleOther {
  visibility: visible !important;
  position: absolute;
  transform: translate(35%, -225%);
  padding: 5px;
  font-family: Avenir;
  font-family: Avenir;
  font-size: 15.5px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
  flex-grow: 0;
  border-radius: 4.6px;
  border: solid 1px #363636;
  background-color: #ffffff;
}

.circleBlue {
  width: 100px;
  height: 100px;
  border: 2px solid #1b73e8;
  opacity: 0.35;
  background-color: #1b73e8;
  border-radius: 100px;
  z-index: 99 !important;
}

.circle {
  width: 100px;
  height: 100px;
  border: 2px solid #606064;
  opacity: 0.15;
  border-radius: 100px;
  flex-grow: 0;
  background-color: #606064;
}
