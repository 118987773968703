.container {
  width: 100vw;
  height: 62px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  padding: 1px 20px;
  box-sizing: border-box;
  flex-grow: 0;
  background-color: #ffffff;
}

.logoHeader {
  margin-top: 1px;
  position: absolute;
  left: 47%;
  right: 50%;
  margin-top: 10px;
  width: 80px;
  height: 35px;
}

.circleBlack {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin: 5px;
  background: #363636;
}

.left {
  cursor: pointer;
  width: 147px;
  height: 40px;
  float: left;
  display: flex;
  margin: 11px 0 11px 26px;
  background: #ebebeb;
  border-radius: 23px;
}

.logoTruck {
  margin: 6px 4px;
}

.textHere {
  margin: 10px 8px 8px 3px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  color: #363636;
}

.right {
  cursor: pointer;
  width: 158px;
  height: 40px;
  float: right;
  display: flex;
  margin: 11px 26px 11px 0px;
  background: #ebebeb;
  border-radius: 23px;
}

.logoPaper {
  margin: 4px 6px;
  transform: scale(0.9);
}
.textPOS {
  margin: 10px 3px 3px 0px;
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  color: #363636;
}
