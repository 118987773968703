.container {
  height: 15%;
  position: relative;
  box-shadow: 0 2.1px 5.3px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  border-radius: 15px 15px 0 0;
}

.containerHistoryPrice {
  height: 10px;
  margin: 0px 13px 10px 10px;
  padding: 15px 0 0 0;
  font-family: Avenir;
  font-size: 15px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.65;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

@media screen and (max-width: 320px) {
  .containerHistoryPrice {
    font-size: 13px;
  }
}

.infoContainer {
  display: flex;
  justify-content: space-around;
}

.average {
  width: 33%;
  flex-grow: 0;
  margin: 0px 10px 0px 11px;
  border-radius: 5px;
  background-color: rgba(240, 240, 240, 0.2);
}

.textAverage {
  height: 30%;
  padding: 8px 10px 0 10px;
  flex-grow: 0;
  font-family: Avenir;
  font-size: 70%;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

@media screen and (max-width: 360px) {
  .textAverage {
    font-size: 58%;
    line-height: 1;
  }
}

.totalAverage {
  height: 30%;
  flex-grow: 0;
  padding: 2px 10px 0 10px;
  font-family: Avenir;
  font-size: 90%;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

@media screen and (max-width: 360px) {
  .totalAverage {
    font-size: 80%;
    padding: 0px 10px 0 10px;
  }
}

.infoMySales {
  width: 33%;
  flex-grow: 0;
  margin: 0px 10px 7px 0px;
  border-radius: 5px;
  background-color: rgba(240, 240, 240, 0.2);
}

.containerTextInfoMySales {
  height: 30%;
  padding: 8px 10px 0 10px;
  flex-grow: 0;
  font-family: Avenir;
  font-size: 70%;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

@media screen and (max-width: 360px) {
  .containerTextInfoMySales {
    font-size: 55%;
  }
}

.totalMonth {
  height: 30%;
  flex-grow: 0;
  padding: 5px 10px 0 10px;
  font-family: Avenir;
  font-size: 90%;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

@media screen and (max-width: 360px) {
  .totalMonth {
    font-size: 80%;
    padding: 3px 10px 0 10px;
  }
}

.countReference {
  width: 30%;
  flex-grow: 0;
  margin: 0px 10px 7px 0px;
  border-radius: 5px;
  background-color: rgba(240, 240, 240, 0.2);
}

.containerTextInfo {
  height: 30%;
  padding: 8px 10px 0 10px;
  flex-grow: 0;
  font-family: Avenir;
  font-size: 70%;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

@media screen and (max-width: 360px) {
  .containerTextInfo {
    font-size: 60%;
  }
}

.total {
  height: 30%;
  flex-grow: 0;
  padding: 5px 10px 0 10px;
  font-family: Avenir;
  font-size: 90%;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

@media screen and (max-width: 360px) {
  .total {
    font-size: 80%;
    padding: 3px 10px 0 10px;
  }
}
