.container {
  width: 23.7%;
  margin: 27px 1.5% 17px 1.5%;
}

.containerHistoryPrice {
  height: 378px;
  width: 100%;
  border: 1px solid #979797;
  box-sizing: border-box;
  border-radius: 10px;
}

.titleContainer {
  width: 93.55%;
  margin: 0 3.23% 0 3.23%;
  border-bottom: 1px solid #e6e6e6;
  height: 20.52%;
}

.titleText {
  padding: 20px 0px 0px 0px;
  font-family: Avenir;
  font-size: 170%;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
}

@media screen and (max-width: 1280px) {
  .titleText {
    font-size: 150%;
  }
}

@media screen and (max-width: 1130px) {
  .titleText {
    font-size: 130%;
  }
}

@media screen and (max-width: 860px) {
  .titleText {
    font-size: 115%;
  }
}

.tableContainer {
  width: 100%;
}

.columName {
  width: 22.5%;
}
.columDates {
  width: 22.5%;
}
.columManager {
  width: 27.5%;
}

.columCustomer {
  width: 27.5%;
}

.columMargins {
  width: 22.5%;
}

table {
  border: none;
}

td {
  border-top: 1px solid #727273;
}

tr:nth-child(even) {
  background-color: #f8f8f8;
}

.contentTableCustomers {
  width: 100%;
}

.headerHistoryPrice {
  width: 18px;
  height: 30px;
  flex-grow: 0;
}

.columDate {
  height: 50%;
}

.textColumnDate {
  text-transform: capitalize;
  width: 100%;
  flex-grow: 0;
  margin: 0px 0 0 10px;
  font-family: Avenir;
  font-size: 70%;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

@media screen and (max-width: 860px) {
  .textColumnDate {
    font-size: 60%;
  }
}

.textColumnMargin {
  width: 100%;
  flex-grow: 0;
  font-family: Avenir;
  font-size: 70%;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  margin: 0 0px 0 -10px;
}

@media screen and (max-width: 860px) {
  .textColumnMargin {
    font-size: 60%;
  }
}

.textColumnPrice {
  height: 100%;
  font-size: 70%;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #000000;
  flex-grow: 0;
  font-family: Avenir;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  width: 100%;
  margin: 7.5% 10px 7.5% -15px;
}

@media screen and (max-width: 860px) {
  .textColumnPrice {
    font-size: 60%;
  }
}

.textTitleColumns {
  font-family: Avenir;
  margin: 0 0 0 10px;
  font-size: 80%;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

@media screen and (max-width: 860px) {
  .textTitleColumns {
    font-size: 70%;
  }
}

.textTitleColumnsPrices {
  margin: 0 15px 0 0;
  font-family: Avenir;
  font-size: 70%;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
}
@media screen and (max-width: 860px) {
  .textTitleColumnsPrices {
    font-size: 65%;
  }
}

.textTitleColumnMargin {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 83%;
  margin: 0 8px 0 -10px;
  text-align: right;
}

@media screen and (max-width: 1330px) {
  .textTitleColumnMargin {
    font-size: 76%;
  }
}

@media screen and (max-width: 1120px) {
  .textTitleColumnMargin {
    font-size: 75%;
  }
}

@media screen and (max-width: 905px) {
  .textTitleColumnMargin {
    font-size: 70%;
  }
}

@media screen and (max-width: 860px) {
  .textTitleColumnMargin {
    font-size: 68%;
  }
}

.titleContainerQuantity {
  height: 10.27%;
  margin: 0 3.23% 0 3.23%;
  border-bottom: 1px solid #e6e6e6;
}

.infoMySales {
  width: 100%;
  margin: 20px 0 0 0px;
  height: 66px;
  background: #e6edf7;
  border-radius: 10px 10px 0 0;
}

.countReference {
  display: flex;
}

.containerTextInfoMySales {
  width: 66.78%;
  height: 31.7%;
  margin: 17px 0px 20px 20px;
}

.textSupMonth {
  font-family: Avenir;
  font-size: 98%;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
  font-weight: 300;
  line-height: 1;
}

@media screen and (max-width: 1120px) {
  .textSupMonth {
    font-size: 83%;
  }
}

.textSubMonth {
  font-size: 98%;
  font-family: Avenir;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

@media screen and (max-width: 1120px) {
  .textSubMonth {
    font-size: 83%;
  }
}

.totalMonth {
  width: 20%;
  height: 25px;
  flex-grow: 0;
  margin: 11px 5% 0px -2.5%;
  font-family: Avenir;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #363636;
  font-size: 40px;
}

@media screen and (max-width: 1120px) {
  .totalMonth {
    font-size: 200%;
  }
}

.info {
  width: 100%;
  height: 102px;
  margin: 0px 0 0 0px;
  border-radius: 0 0 10px 10px;
  background-color: rgba(240, 240, 240, 0.2);
}

.containerTextInfo {
  width: 56.78%;
  height: 31.7%;
  margin: 12px 0px 2px 20px;
}

.textSup {
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  flex-grow: 0;
  font-family: Avenir;
  font-size: 15px;
  line-height: 1;
  color: #363636;
}

@media screen and (max-width: 1120px) {
  .textSup {
    font-size: 95%;
  }
}

.textSub {
  flex-grow: 0;
  font-family: Avenir;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
  font-size: 20px;
  font-weight: 900;
}

@media screen and (max-width: 1120px) {
  .textSub {
    font-size: 95%;
  }
}

.total {
  width: 67px;
  height: 55px;
  margin: 7px 8% -5px 5%;
  flex-grow: 0;
  font-family: Avenir;
  font-size: 40px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #363636;
}

@media screen and (max-width: 1120px) {
  .total {
    font-size: 200%;
  }
}

.average {
  display: flex;
}

.containerAverage {
  width: 56.78%;
  height: 31.7%;
  margin: 0px 0px 0px 20px;
}

.textSupAverage {
  width: 136px;
  flex-grow: 0;
  margin: 0px 77px 0 0px;
  font-family: Avenir;
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

@media screen and (max-width: 1120px) {
  .textSupAverage {
    font-size: 85%;
  }
}

.textSubAverage {
  width: 136px;
  flex-grow: 0;
  margin: 0px 77px 20px 0px;
  font-family: Avenir;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
  font-weight: 900;
}

.totalAverage {
  width: 50%;
  margin: 8px 6.5% 0 -10px;
  font-family: Avenir;
  font-size: 150%;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #363636;
}

@media screen and (max-width: 1120px) {
  .textSubAverage {
    font-size: 95%;
  }
  .totalAverage {
    font-size: 120%;
  }
}
