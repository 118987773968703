.body {
  background-color: #f8f8f8;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 1px;
}

.generalContainer {
  width: 100%;
  height: 91.9%;
  border-radius: 4px;
  background-color: #ffffff;
}

.containerCards {
  width: 100%;
  height: 100%;
  display: flex;
}

.gridBody {
  height: 100%;
  width: 100%;
}

.gridBodySup {
  width: 100%;
  height: 18%;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0 0 !important;
}

.gridBodySuper {
  width: 100%;
  height: 9%;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0 0 !important;
}

.textSup {
  flex-grow: 0;
  padding: 14px 1% 15px 10px;
  font-family: Avenir;
  border-bottom: 1px solid #e6e6e6;
  background-color: #f8f8f8;
  font-size: 90%;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

.pricesContainer {
  height: 45%;
  width: 100%;
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: space-around;
  box-shadow: 0 1.1px 1.3px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
}

.price {
  width: 21%;
  flex-grow: 0;
  justify-content: space-evenly;
  margin: 6px -18px 0px 4px;
}

.priceTextBlue {
  font-family: Avenir;
  font-size: 67%;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #1b73e8;
}

@media screen and (max-width: 320px) {
  .priceTextBlue {
    font-size: 60%;
  }
}

.priceNumberBlue {
  font-family: Avenir;
  font-size: 80%;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #1b73e8;
}

@media screen and (max-width: 320px) {
  .priceNumberBlue {
    font-size: 75%;
  }
}

.priceText {
  font-family: Avenir;
  font-size: 90%;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

@media screen and (max-width: 320px) {
  .priceText {
    font-size: 80%;
  }
}

.priceNumber {
  font-family: Avenir;
  font-size: 80%;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

@media screen and (max-width: 320px) {
  .priceNumber {
    font-size: 75%;
  }
}

.map {
  grid-column: 1/4;
  min-height: 12rem;
  width: 100%;
  height: 100%;
}

.textBubble {
  width: 89.3px;
  height: 26.1px;
  margin: 0 0 6.9px;
  font-family: Avenir;
  font-size: 12.4px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-bottom: 2px solid #ffffff;
}

.noData {
  width: 713px;
  height: 33px;
  margin: 120px 0 0 357px;
  font-family: Avenir;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #191919;
}
