.gridBodyMap {
  height: 67%;
  margin-top: -12px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  position: relative;
}

.container {
  touch-action: pan-x pan-y;
}

.map {
  border-radius: 10px !important;
  width: auto;
  z-index: -10 !important;
}

.textBubble {
  width: 89.3px;
  height: 26.1px;
  margin: 0 0 6.9px;
  font-family: Avenir;
  font-size: 12.4px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-bottom: 2px solid #ffffff;
}

.popupBubble {
  visibility: visible !important;
  position: absolute;
  transform: translate(30%, -200%);
  padding: 5px;
  font-family: Avenir;
  background-color: #1b73e8;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  z-index: 98;
  flex-grow: 0;
  border-radius: 4.6px;
  border: solid 1px #ffffff;
  background-color: #1b73e8;
}

.popupBubbleOther {
  visibility: visible !important;
  position: absolute;
  transform: translate(38%, -205%);
  padding: 5px;
  font-family: Avenir;
  font-family: Avenir;
  font-size: 15.5px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
  flex-grow: 0;
  border-radius: 4.6px;
  border: solid 1px #363636;
  background-color: #ffffff;
}

.circleBlue {
  width: 100px;
  height: 100px;
  border: 2px solid #1b73e8;
  opacity: 0.35;
  background-color: #1b73e8;
  border-radius: 100px;
  z-index: 99999999 !important;
}

.circle {
  width: 100px;
  height: 100px;
  border: 2px solid #606064;
  opacity: 0.15;
  border-radius: 100px;
  flex-grow: 0;
  background-color: #606064;
}

.goForm {
  margin: 8.3% 25.7% 0 25.7%;
  padding: 5px 26.3px;
  border-radius: 7.5px;
  box-shadow: 0 2.5px 7.5px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  width: 48.6%;
  background-color: #1b73e8;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.75px;
  flex-grow: 0;
  font-family: Avenir;
  font-size: 96%;
  text-align: center;
  color: #ffffff;
}

@media screen and (max-width: 320px) {
  .goForm {
    font-size: 80%;
  }
}

.POS {
  position: relative;
  width: 51.7%;
  height: 88.1%;
  margin: 0px 0 -60% 0;
  padding: 0 24.8% 10% 24.8%;
  /* transform: scale(1); */
  /* z-index: -1; */
}

.map2 {
  background-color: #f8f8f8;
  border-radius: 10px !important;
  height: 100%;
}

.gridBodyMap2 {
  height: 77%;
  margin-top: -12px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  position: relative;
}
