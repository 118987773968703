.body {
  background-color: #f8f8f8;
  width: 100%;
  height: 100%;
  padding-top: 62px;
}

.bodyMobile {
  background-color: #f8f8f8;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 1px;
}

.generalContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 82px;
  padding: 16px 0 2.4% 0;
  border-radius: 4px;
  background-color: #ffffff;
}
.generalContainerMobile {
  width: 100%;
  height: 91.9%;
  border-radius: 4px;
  background-color: #ffffff;
}

.containerCards {
  display: flex;
}

.containerCardsMobile {
  width: 100%;
  height: 100%;
  display: flex;
}

.gridBody {
  height: 566px;
  width: 47.3%;
  display: grid;
  margin: 27px 0px 17px 1.5%;
  border-radius: 10px 10px 0 0 !important;
  z-index: unset;
  border: solid 2px #1b73e8;
  position: relative;
}

.gridBodyMobile {
  height: 100%;
  width: 100%;
}

.gridBodySup {
  height: 151px !important;
  width: 100%;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  left: 0%;
  top: 0%;
  border-radius: 10px 10px 0 0 !important;
  position: absolute;
}

.textSup {
  height: 58.5px;
  flex-grow: 0;
  margin: 18px 1% 10px 1%;
  font-family: Avenir;
  font-size: 25px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: #363636;
  width: 98%;
  border-bottom: 1px solid #e6e6e6;
}

@media screen and (max-width: 1120px) {
  .textSup {
    font-size: 155%;
  }
}

.gridBodyMap {
  height: 415px;
  width: 99.99%;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  left: 0%;
  top: 26%;
  position: absolute;
}

.pricesContainer {
  display: flex;
  justify-content: space-around;
}

.price {
  width: 120px;
  height: 58.5px;
  flex-grow: 0;
  margin: 0px 10.5px 0px 17px;
}

.priceTextBlue {
  font-family: Avenir;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1b73e8;
}

@media screen and (max-width: 1120px) {
  .priceTextBlue {
    font-size: 70%;
  }
}

.priceNumberBlue {
  font-family: Avenir;
  font-size: 25px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1b73e8;
}

@media screen and (max-width: 1120px) {
  .priceNumberBlue {
    font-size: 150%;
  }
}
.priceText {
  font-family: Avenir;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}

@media screen and (max-width: 1120px) {
  .priceText {
    font-size: 70%;
  }
}

.priceNumber {
  font-family: Avenir;
  font-size: 25px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #363636;
}
@media screen and (max-width: 1120px) {
  .priceNumber {
    font-size: 150%;
  }
}

.map {
  grid-column: 1/4;
  min-height: 12rem;
  width: 100%;
  height: 100%;
}

.noData {
  width: 713px;
  height: 33px;
  margin: 120px 0 0 357px;
  font-family: Avenir;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #191919;
}

.noDataMobile {
  /* width: 713px; */
  height: 33px;
  margin: 120px 0 0 357px;
  font-family: Avenir;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #191919;
}
