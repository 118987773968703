.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.horizontalCenter {
  text-align: center;
  display: block;
  margin: auto;
}

.verticalCenter {
  display: flex !important;
  align-content: center !important;
  align-items: center !important;
}

/** 
 * The default focus style is likely provided by Bootstrap or the browser
 * but here we override everything else with a visually appealing cross-
 * browser solution that works well on all focusable page elements
 * including buttons, links, inputs, textareas, and selects.
 */
*:focus { 
  outline: 0 !important;
  box-shadow:
    0 0 0 .2rem #fff, /* use site bg color to create whitespace for faux focus ring */
    0 0 0 .35rem #069 !important; /* faux focus ring color */
}

/**
 * Undo the above focused button styles when the element received focus
 * via mouse click or touch, but not keyboard navigation.
 */
*:focus:not(:focus-visible) {
  outline: 0 !important;
  box-shadow: none !important;
}