@font-face {
  font-family: "avenir";
  src: url("../fonts/avenir/AvenirNextLTPro-Regular.eot");
  src: url("../fonts/avenir/AvenirNextLTPro-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/avenir/AvenirNextLTPro-Regular.woff2") format("woff2"),
    url("../fonts/avenir/AvenirNextLTPro-Regular.woff") format("woff"),
    url("../fonts/avenir/AvenirNextLTPro-Regular.ttf") format("truetype"),
    url("../fonts/avenir/AvenirNextLTPro-Regular.svg#AvenirNextLTPro-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "avenir";
  src: url("../fonts/avenir/Avenir-Medium.eot");
  src: url("../fonts/avenir/Avenir-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/avenir/Avenir-Medium.woff2") format("woff2"),
    url("../fonts/avenir/Avenir-Medium.woff") format("woff"),
    url("../fonts/avenir/Avenir-Medium.ttf") format("truetype"),
    url("../fonts/avenir/Avenir-Medium.svg#Avenir-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "avenir";
  src: url("../fonts/avenir/AvenirLTStd-Black.eot");
  src: url("../fonts/avenir/AvenirLTStd-Black.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/avenir/AvenirLTStd-Black.woff2") format("woff2"),
    url("../fonts/avenir/AvenirLTStd-Black.woff") format("woff"),
    url("../fonts/avenir/AvenirLTStd-Black.ttf") format("truetype"),
    url("../fonts/avenir/AvenirLTStd-Black.svg#AvenirLTStd-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "avenir";
  src: url("../fonts/avenir/AvenirNextLTPro-BoldCn.eot");
  src: url("../fonts/avenir/AvenirNextLTPro-BoldCn.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/avenir/AvenirNextLTPro-BoldCn.woff2") format("woff2"),
    url("../fonts/avenir/AvenirNextLTPro-BoldCn.woff") format("woff"),
    url("../fonts/avenir/AvenirNextLTPro-BoldCn.ttf") format("truetype"),
    url("../fonts/avenir/AvenirNextLTPro-BoldCn.svg#AvenirNextLTPro-BoldCn")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "avenir";
  src: url("../fonts/avenir/Avenir-Heavy.eot");
  src: url("../fonts/avenir/Avenir-Heavy.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/avenir/Avenir-Heavy.woff2") format("woff2"),
    url("../fonts/avenir/Avenir-Heavy.woff") format("woff"),
    url("../fonts/avenir/Avenir-Heavy.ttf") format("truetype"),
    url("../fonts/avenir/Avenir-Heavy.svg#Avenir-Heavy") format("svg");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Book";
  src: url("../fonts/avenir/Avenir-Book.eot");
  src: url("../fonts/avenir/Avenir-Book.eot?#iefix") format("embedded-opentype"),
    url("../fonts/avenir/Avenir-Book.woff2") format("woff2"),
    url("../fonts/avenir/Avenir-Book.woff") format("woff"),
    url("../fonts/avenir/Avenir-Book.ttf") format("truetype"),
    url("../fonts/avenir/Avenir-Book.svg#Avenir-Book") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "avenir";
  font-display: auto;
  font-display: swap;
}

@media screen and (max-width: 992px) {
  * {
    font-size: 14px;
  }
}
