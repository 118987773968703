body {
  background: rgba(244, 244, 244, 0.8);
  animation: bgg 16087ms ease-in alternate infinite;
}
@keyframes bgg {
  0% {
    background: rgba(244, 244, 244, 0.8);
  }
  50% {
    background: rgba(244, 244, 244, 0.8);
  }
  100% {
    background: rgba(244, 244, 244, 0.8);
  }
}
.loader {
  z-index: 99999;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 102%;
  height: 115%;
  margin: auto;
}

.loader .circle {
  position: absolute;
  margin-left: 45.9%;
  margin-top: -30rem;
  width: 94px;
  height: 94px;
  opacity: 0;
  transform: rotate(225deg);
  animation-iteration-count: infinite;
  animation-name: orbit;
  animation-duration: 5.5s;
}
.loader .circle:after {
  content: "";
  position: absolute;
  width: 8.5px;
  height: 8.5px;
  border-radius: 5px;
  background: #1b73e8;
  box-shadow: 0 0 9px #1b73e8;
}
.loader .circle:nth-child(2) {
  animation-delay: 240ms;
}
.loader .circle:nth-child(3) {
  animation-delay: 480ms;
}
.loader .circle:nth-child(4) {
  animation-delay: 720ms;
}
.loader .circle:nth-child(5) {
  animation-delay: 960ms;
}
.loader .bg {
  position: relative;
  width: inherit;
  height: inherit;
  margin-left: -16px;
  margin-top: -16px;
  border-radius: 13px;
  background-color: rgba(0, 153, 255, 0.69);
  animation: bgg 16087ms ease-in alternate infinite;
}
@keyframes orbit {
  0% {
    transform: rotate(225deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  7% {
    transform: rotate(345deg);
    animation-timing-function: linear;
  }
  30% {
    transform: rotate(455deg);
    animation-timing-function: ease-in-out;
  }
  39% {
    transform: rotate(690deg);
    animation-timing-function: linear;
  }
  70% {
    transform: rotate(815deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(945deg);
    animation-timing-function: ease-out;
  }
  76% {
    transform: rotate(945deg);
    opacity: 0;
  }
  100% {
    transform: rotate(945deg);
    opacity: 0;
  }
}
