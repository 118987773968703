.searchBar {
  margin: 10px 26.2% 0px 26.2%;
  border-radius: 10px;
  border: solid 1px #d7d7d7;
  display: flex;
  justify-content: space-between;
  width: 47.6%;
  height: 40px;
  flex-grow: 0;
  border-radius: 10px;
  background-color: rgba(240, 240, 240, 0.2);
}

.lupa {
  width: 7.4%;
  transform: scale(0.6);
  border: solid 0.5px #ffffff;
}

.inputText {
  opacity: 0.3;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  border: #ffffff;
  width: 92.6%;
  border-radius: 10px;
  color: #2f2f2f;
}

@media screen and (max-width: 1120px) {
  .inputText {
    font-size: 70%;
  }
}
