.container {
  z-index: 99999;
  position: absolute;
  top: 12%;
  left: 33%;
  right: 33%;
  width: 466px;
  height: 367px;
  flex-grow: 0;
  border-radius: 30px;
  box-shadow: 0 20px 60px 0 rgba(54, 54, 54, 0.18);
  background-color: #ffffff;
}

.containerTitle {
  width: 436px;
  height: 88px;
  flex-grow: 0;
  margin: 40px 15px 0 15px;
  font-family: Avenir;
  font-size: 40px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -1.8px;
  text-align: center;
  color: #363636;
}
@media screen and (max-width: 1120px) {
  .containerTitle {
    font-size: 38px;
  }
}

.containerSubTitle {
  width: 436px;
  height: 42px;
  flex-grow: 0;
  margin: 26px 15px 0 15px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.8px;
  text-align: center;
  color: #363636;
}

@media screen and (max-width: 1120px) {
  .containerSubTitle {
    font-size: 18px;
  }
}

.list {
  display: block;
  max-width: 100%;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.03);
  background-image: linear-gradient(to bottom, #ffffff 0%, #f7f7f7 100%);
  background-repeat: no-repeat;
  background-position: right 0.65em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
  width: 386px;
  height: 60px;
  margin: 0px 40px 10px 40px;
  padding: 12.5px 32.5px 12.5px 22.5px;
  border-radius: 7.5px;
  border: solid 1.3px rgba(54, 54, 54, 0.4);
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  color: #979797;
  text-align: left;
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.list::-ms-expand {
  display: none;
}

.valueDefault {
  color: #979797;
  display: none;
  width: 36px;
  text-align: left;
  font-family: Avenir;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.list:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

.list option {
  height: 18px;
  flex-grow: 0;
  font-family: Avenir;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #58595b;
}

@media screen and (max-width: 1120px) {
  .list option {
    font-size: 13px;
  }
}

.buttonNoChecked {
  width: 386px;
  height: 60px;
  margin: 10px 40px 20px 40px;
  padding: 20px 146.5px 20px 147.5px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.12);
  font-family: Avenir;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.38);
}

.buttonChecked {
  width: 386px;
  height: 60px;
  margin: 10px 40px 20px 40px;
  padding: 20px 146.5px 20px 147.5px;
  border-radius: 10px;
  background-color: #1b73e8;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}
